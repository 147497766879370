import { Env, parseUserAgent } from './env'
import { TmapAppInterfaceParams } from './internal-types'
import { TmapAndroidInterfaceImpl } from './TmapAndroidInterfaceImpl'
import { TmapAppInterface } from './TmapAppInterface'
import { TmapIosInterfaceImpl } from './TmapIosInterfaceImpl'
import {
  addNativeEventListener,
  callCustomUrl,
  getTmapAppDownloadUrl,
  log,
  nativeCallbacks,
  nativeEventListeners,
} from './utils'

let delegator: TmapAppInterface
let env: Env = new Env({})

class TmapApp implements TmapAppInterface {

  readonly utils = {
    log: log.bind(this),
    getTmapAppDownloadUrl: getTmapAppDownloadUrl.bind(this),
    callCustomUrl: callCustomUrl.bind(this),
    addNativeEventListener: addNativeEventListener.bind(this),
  }

  get env(): Readonly<Env> {
    return env
  }

  init() {
    env = parseUserAgent(window.navigator.userAgent)
    delegator = env.isIOS ? new TmapIosInterfaceImpl(this) : new TmapAndroidInterfaceImpl(this)

    window.TmapWebView = {
      // callbackJS 규격으로 콜백될 펑션 저장.
      nativeCallback: nativeCallbacks,

      // makeDialogPopup 콜백에만 사용.
      // makeDialogPopup 앱인터페이스는 콜백 스크립트가 TmapWebView.callback 으로 고정되어 있음.
      //
      // 주의!
      // 콜백 동작이 iOS, Android 다름.
      // makeDialogPopup 호출할때,
      // iOS는 callJS='0'을 넘기면 number타입 0값이, callJS='abcd'를 넘기면 window.abcd 프로퍼티값이 callJS로 넘어옴.
      // iOS에서 자바스크립트 실행할때 callJS값을 따옴표로 감싸지 않은 것으로 보임.
      // Android는 항상 string타입 값으로 넘어옴.
      callback: (callJS: string | Function, result: boolean) => {
        if (typeof callJS === 'function') {
          callJS(result)
        } else {
          nativeCallbacks[callJS](result)
        }
      },

      // 웹뷰가 포어그라운드로 백그라운드로 바뀌면 호출됨.
      onPause: () => {
        nativeEventListeners.onPause.forEach(listener => listener())
      },

      // 웹뷰가 백그라운에서 포어그라운드로 바뀌면 호출됨.
      onResume: () => {
        nativeEventListeners.onResume.forEach(listener => listener())
      },

      // 앱이 특정한 경우에 onRefresh를 호출해줌. 현재 티앱안에 하단탭으로 열리는 임베드된 웹뷰만 호출하고 있음.(T지금, 운전점수)
      onRefresh: () => {
        nativeEventListeners.onRefresh.forEach(listener => listener())
      },

      /**
       * 안드로이드 전용. 9.1.2 부터.
       * handleBackKeyEventFromWeb(true) 호출후 os 백버튼 눌리면 호출됨.
       *
       */
      onHardwareBackKeyPressed: () => {
        nativeEventListeners.onHardwareBackKeyPressed.forEach(listener => listener())
      },

      /**
       * 안드로이드 전용. 9.9 부터.
       * os 백버튼 눌릴때마다 호출됨.
       * @param backKeyDisabled handleBackKeyEventFromWeb 호출로 설정한 상태.
       */
      onBackKeyPressedEvent: (backKeyDisabled: boolean) => {
        nativeEventListeners.onBackKeyPressedEvent.forEach(listener => listener(backKeyDisabled))
      },

      /**
       * 10.9.0 부터.
       * 승하차 알림이 종료 상태(목적지 도착, 이탈, 시간 초과, 사용자 취소 등)로 변경될 경우 해당 인터페이스를 네이티브 앱에서 호출.
       * 단, 지하철 노선도 웹 뷰가 onResume ~ onPause 사이의 라이프 사이클(보이는 경우) 및 페이지가 로드 완료된 경우에만 호출.
       * 앱에서는 try-catch로 처리되어있어, 웹 뷰에서 해당 메소드가 없을 경우 별도의 에러가 발생하지는 않음.
       */
      onTransitAlarmStopped: () => {
        nativeEventListeners.onTransitAlarmStopped.forEach(listener => listener())
      },
    }

    // 구버전 티맵 호환.
    // 구버전 티맵은 앱 내부에서 callbackJS 실행할때 앞에 "TmapWebView." 붙여 실행함.
    // 예) callbackJS=TmapWebView.appCallback 넘기면 TmapWebView.TmapWebView.appCallback 실행.
    // @ts-ignore
    window.TmapWebView.TmapWebView = window.TmapWebView
  }

  makeToast(args: TmapAppInterfaceParams['makeToast']) {
    delegator.makeToast(args)
  }

  makeDialogPopup(args: TmapAppInterfaceParams['makeDialogPopup']) {
    return delegator.makeDialogPopup(args)
  }

  onBackKeyPressed(args?: TmapAppInterfaceParams['onBackKeyPressed']) {
    delegator.onBackKeyPressed(args)
  }

  selectImage() {
    return delegator.selectImage()
  }

  openBrowser(args: TmapAppInterfaceParams['openBrowser']) {
    delegator.openBrowser(args)
  }

  phoneCall(args: TmapAppInterfaceParams['phoneCall']) {
    delegator.phoneCall(args)
  }

  getTipOffList(args?: TmapAppInterfaceParams['getTipOffList']) {
    return delegator.getTipOffList(args)
  }

  deleteTipOff(args: TmapAppInterfaceParams['deleteTipOff']) {
    delegator.deleteTipOff(args)
  }

  search(args: TmapAppInterfaceParams['search']) {
    return delegator.search(args)
  }

  searchSubway(args: TmapAppInterfaceParams['searchSubway']) {
    return delegator.searchSubway(args)
  }

  getRecentDestination(args: TmapAppInterfaceParams['getRecentDestination']) {
    return delegator.getRecentDestination(args)
  }

  registPoi(args: TmapAppInterfaceParams['registPoi']) {
    delegator.registPoi(args)
  }

  showPoiDetailInfo(args: TmapAppInterfaceParams['showPoiDetailInfo']) {
    delegator.showPoiDetailInfo(args)
  }

  copyClipboard(args: TmapAppInterfaceParams['copyClipboard']) {
    delegator.copyClipboard(args)
  }

  updateAccessKey(args: TmapAppInterfaceParams['updateAccessKey']) {
    delegator.updateAccessKey(args)
  }

  clearCache() {
    delegator.clearCache()
  }

  notifyChangedUserName(args: TmapAppInterfaceParams['notifyChangedUserName']) {
    delegator.notifyChangedUserName(args)
  }

  openOilDiscount(args?: TmapAppInterfaceParams['openOilDiscount']) {
    delegator.openOilDiscount(args)
  }

  openNearBy(args?: TmapAppInterfaceParams['openNearBy']) {
    delegator.openNearBy(args)
  }

  getAccessKey(args?: TmapAppInterfaceParams['getAccessKey']) {
    return delegator.getAccessKey(args)
  }

  notifyChangedInfo(args: TmapAppInterfaceParams['notifyChangedInfo']) {
    delegator.notifyChangedInfo(args)
  }

  getDisplayInfo(args?: TmapAppInterfaceParams['getDisplayInfo']) {
    return delegator.getDisplayInfo(args)
  }

  getDeviceId(args?: TmapAppInterfaceParams['getDeviceId']) {
    return delegator.getDeviceId(args)
  }

  getCarrierName(args?: TmapAppInterfaceParams['getCarrierName']) {
    return delegator.getCarrierName(args)
  }

  getAppSyncApiKey(args?: TmapAppInterfaceParams['getAppSyncApiKey']) {
    return delegator.getAppSyncApiKey(args)
  }

  getEUK(args?: TmapAppInterfaceParams['getEUK']) {
    return delegator.getEUK(args)
  }

  openServiceByName<Data>(args: TmapAppInterfaceParams['openServiceByName']) {
    return delegator.openServiceByName<Data>(args)
  }

  openServiceByUrl(args: TmapAppInterfaceParams['openServiceByUrl']) {
    return delegator.openServiceByUrl(args)
  }

  showNativeTitle(args: TmapAppInterfaceParams['showNativeTitle']) {
    delegator.showNativeTitle(args)
  }

  playTTS(args: TmapAppInterfaceParams['playTTS']) {
    return delegator.playTTS(args)
  }

  stopTTS() {
    delegator.stopTTS()
  }

  recordEvent(args: TmapAppInterfaceParams['recordEvent']) {
    delegator.recordEvent(args)
  }

  getUserSetting(args: TmapAppInterfaceParams['getUserSetting']) {
    return delegator.getUserSetting(args)
  }

  shareMessage(args: TmapAppInterfaceParams['shareMessage']) {
    delegator.shareMessage(args)
  }

  share(args: TmapAppInterfaceParams['share']) {
    delegator.share(args)
  }

  showGNB(args: TmapAppInterfaceParams['showGNB']) {
    delegator.showGNB(args)
  }

  getCurrentPosition(args?: TmapAppInterfaceParams['getCurrentPosition']) {
    return delegator.getCurrentPosition(args)
  }

  getLoginMethod(args?: TmapAppInterfaceParams['getLoginMethod']) {
    return delegator.getLoginMethod(args)
  }

  requestTidLogin() {
    return delegator.requestTidLogin()
  }

  requestConnectCi() {
    return delegator.requestConnectCi()
  }

  startReportLocation(args: TmapAppInterfaceParams['startReportLocation']) {
    delegator.startReportLocation(args)
  }

  stopReportLocation() {
    delegator.stopReportLocation()
  }

  startPaymentActivity(args: TmapAppInterfaceParams['startPaymentActivity']) {
    return delegator.startPaymentActivity(args)
  }

  startPointActivity(args: TmapAppInterfaceParams['startPointActivity']) {
    return delegator.startPointActivity(args)
  }

  handleBackKeyEventFromWeb(args: TmapAppInterfaceParams['handleBackKeyEventFromWeb']) {
    delegator.handleBackKeyEventFromWeb(args)
  }

  selectBottomNavigationItem(args: TmapAppInterfaceParams['selectBottomNavigationItem']) {
    delegator.selectBottomNavigationItem(args)
  }

  setBottomNavigationVisibility(args: TmapAppInterfaceParams['setBottomNavigationVisibility']) {
    delegator.setBottomNavigationVisibility(args)
  }

  setOrientation(args: TmapAppInterfaceParams['setOrientation']) {
    delegator.setOrientation(args)
  }

  isRoadAddressType(args?: TmapAppInterfaceParams['isRoadAddressType']) {
    return delegator.isRoadAddressType(args)
  }

  clearPushHistory(args: TmapAppInterfaceParams['clearPushHistory']) {
    delegator.clearPushHistory(args)
  }

  setAsumUserInfo() {
    delegator.setAsumUserInfo()
  }

  getRedDotList(args?: TmapAppInterfaceParams['getRedDotList']) {
    return delegator.getRedDotList(args)
  }

  updateRedDotList(args: TmapAppInterfaceParams['updateRedDotList']) {
    delegator.updateRedDotList(args)
  }

  sendMomentHappen(args: TmapAppInterfaceParams['sendMomentHappen']) {
    delegator.sendMomentHappen(args)
  }

  getDeviceAdId(args?: TmapAppInterfaceParams['getDeviceAdId']) {
    return delegator.getDeviceAdId(args)
  }

  getDeviceServiceVendorId(args?: TmapAppInterfaceParams['getDeviceServiceVendorId']) {
    return delegator.getDeviceServiceVendorId(args)
  }

  getCurrentMapContext(args?: TmapAppInterfaceParams['getCurrentMapContext']) {
    return delegator.getCurrentMapContext(args)
  }

  setCurrentMapContext(args: TmapAppInterfaceParams['setCurrentMapContext']) {
    delegator.setCurrentMapContext(args)
  }

  getWebViewMountTime(args?: TmapAppInterfaceParams['getWebViewMountTime']) {
    return delegator.getWebViewMountTime(args)
  }

  getSessionId(args?: TmapAppInterfaceParams['getSessionId']) {
    return delegator.getSessionId(args)
  }

  getLastPosition(args?: TmapAppInterfaceParams['getLastPosition']) {
    return delegator.getLastPosition(args)
  }

  getPhoneNumber() {
    return delegator.getPhoneNumber()
  }

  requestCILogin() {
    return delegator.requestCILogin()
  }

  requestCIValidation() {
    return delegator.requestCIValidation()
  }

  startQrCodeScanActivity(args: TmapAppInterfaceParams['startQrCodeScanActivity']) {
    return delegator.startQrCodeScanActivity(args)
  }

  getTmapInfo() {
    return delegator.getTmapInfo()
  }

  getRemoteConfig(args: TmapAppInterfaceParams['getRemoteConfig']) {
    return delegator.getRemoteConfig(args)
  }

  openFavoriteRoute() {
    delegator.openFavoriteRoute()
  }

  getFavoriteList(args?: TmapAppInterfaceParams['getFavoriteList']) {
    return delegator.getFavoriteList(args)
  }

  useStatusBarArea(args: TmapAppInterfaceParams['useStatusBarArea']) {
    delegator.useStatusBarArea(args)
  }

  setStatusBarTextColor(args: TmapAppInterfaceParams['setStatusBarTextColor']) {
    delegator.setStatusBarTextColor(args)
  }

  closeAndReturnData(args?: TmapAppInterfaceParams['closeAndReturnData']) {
    delegator.closeAndReturnData(args)
  }

  pickContact() {
    return delegator.pickContact()
  }

  showSoftKeyboard(args: TmapAppInterfaceParams['showSoftKeyboard']) {
    delegator.showSoftKeyboard(args)
  }

  openInAppBrowser(args: TmapAppInterfaceParams['openInAppBrowser']) {
    delegator.openInAppBrowser(args)
  }

  toggleFavorite(args: TmapAppInterfaceParams['toggleFavorite']) {
    return delegator.toggleFavorite(args)
  }

  getFavoriteState(args: TmapAppInterfaceParams['getFavoriteState']) {
    return delegator.getFavoriteState(args)
  }

  togglePublicTransportFavorite(args: TmapAppInterfaceParams['togglePublicTransportFavorite']) {
    return delegator.togglePublicTransportFavorite(args)
  }

  getPublicTransportFavoriteState(args: TmapAppInterfaceParams['getPublicTransportFavoriteState']) {
    return delegator.getPublicTransportFavoriteState(args)
  }

  openSubwayRouteDetail(args: TmapAppInterfaceParams['openSubwayRouteDetail']) {
    return delegator.openSubwayRouteDetail(args)
  }

  getNearestSubway() {
    return delegator.getNearestSubway();
  }

  saveSubwayRoute(args: TmapAppInterfaceParams['saveSubwayRoute']) {
    delegator.saveSubwayRoute(args)
  }

  getWebPolicy<Data>(args: TmapAppInterfaceParams['getWebPolicy']) {
    return delegator.getWebPolicy<Data>(args)
  }

  openAgreementDialog(args: TmapAppInterfaceParams['openAgreementDialog']) {
    delegator.openAgreementDialog(args)
  }

  setAgreementExpiredDate(args: TmapAppInterfaceParams['setAgreementExpiredDate']) {
    delegator.setAgreementExpiredDate(args)
  }

  setAllow(args: TmapAppInterfaceParams['setAllow']) {
    delegator.setAllow(args)
  }

  isTransitAlarmTrackingSubwayRoute(args: TmapAppInterfaceParams['isTransitAlarmTrackingSubwayRoute']) {
    return delegator.isTransitAlarmTrackingSubwayRoute(args)
  }

  markTransitAlarmTooltipAsShown() {
    delegator.markTransitAlarmTooltipAsShown()
  }

  setMyTabTopButtonGroupVisibility(args: TmapAppInterfaceParams['setMyTabTopButtonGroupVisibility']) {
    delegator.setMyTabTopButtonGroupVisibility(args)
  }

  shouldShowTransitAlarmTooltip() {
    return delegator.shouldShowTransitAlarmTooltip()
  }

  updateAllRedDots() {
    delegator.updateAllRedDots()
  }

  getAddress(args: TmapAppInterfaceParams['getAddress']) {
    return delegator.getAddress(args)
  }
}

export { TmapApp }
