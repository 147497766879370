import isString from 'lodash/isString'
import qs from 'qs'
import { Dictionary } from './internal-types'
import { TmapApp } from './TmapApp'
import { DrivingTraceData, NativeEventName, TipOffList } from './types'

const PREFIX_CALLBACK_ID = 'TmapWebView.nativeCallback.'
const nativeCallbacks: { [name: string]: (...args: any[]) => void } = {}
const nativeEventListeners: { [eventName in NativeEventName]: ((...args: any) => void)[] } = {
  onPause: [],
  onResume: [],
  onRefresh: [],
  onHardwareBackKeyPressed: [],
  onBackKeyPressedEvent: [],
  onTransitAlarmStopped: [],
}
let uid = 0

function generateCallbackId() {
  return `callback_${uid++}`
}

function addNativeCallback(callback: (...args: any[]) => void, callbackId: string = generateCallbackId()) {
  nativeCallbacks[callbackId] = (...result: any[]) => {
    callback(...result)
    removeNativeCallback(callbackId)
  }
  return `${PREFIX_CALLBACK_ID}${callbackId}`
}

function removeNativeCallback(callbackId: string) {
  delete nativeCallbacks[callbackId]
}

function addNativeEventListener<ReturnValue extends any[]>(eventName: NativeEventName, listener: (...args: ReturnValue) => void) {
  nativeEventListeners[eventName].push(listener)
  return () => {
    nativeEventListeners[eventName].splice(nativeEventListeners[eventName].indexOf(listener), 1)
  }
}

function transformTipOffList(rawData: string) {
  const data = JSON.parse(rawData)
  const result = (data.result as any[]).reduce(
    (list, item: any) => {
      try {
        const deserialized = {
          id: item.id,
          data: isString(item.data) ? JSON.parse(item.data) : item.data,
        }
        list.push(deserialized)
      } catch {}
      return list
    },
    [] as DrivingTraceData[],
  )

  return { result } as TipOffList
}

function log(this: TmapApp, ...args: any[]) {
  const envName = this.env.isInApp ? this.env.isIOS ? 'iOS' : 'Android' : 'Browser'
  console.log(`[TmapInterface][${envName}]`, ...args)
}

function callCustomUrl(this: TmapApp, pathname: string, params: { [key: string]: any } = {}) {
  const paramString = qs.stringify(params, { skipNulls: true })
  const scheme = pathname.indexOf('://') !== -1 ? '' : 'tmapjs://'
  const customUrl = `${scheme}${pathname}${paramString ? `?${paramString}` : ''}`

  this.utils.log(customUrl)

  const iframe = document.createElement('IFRAME')
  iframe.style.display = 'block'
  iframe.setAttribute('frameborder', '0')
  iframe.setAttribute('width', '0')
  iframe.setAttribute('height', '0')
  iframe.setAttribute('src', customUrl)
  document.body.appendChild(iframe)
  document.body.removeChild(iframe)
}

function getTmapAppDownloadUrl(this: TmapApp) {
  if (this.env.isIOS) {
    return 'https://itunes.apple.com/kr/app/t-map-for-sk/id431589174?mt=8'
  } else if (this.env.userAgent.toLocaleLowerCase().indexOf('tmap/sk/android') > -1) {
    return 'http://onesto.re/0000163382'
  } else {
    return 'https://play.google.com/store/apps/details?id=com.skt.tmap.ku'
  }
}

/**
 * 미지원 펑션일때 또는 브라우저 환경에서 리턴값이 있는 펑션의 promise를 resolve시켜야 하기 때문에 timeout으로 감쌈.
 * @param execute
 * @param options
 */
function safetyAsyncCall<ReturnType extends unknown>(
  execute: (callbackJS: string) => void,
  options: {
    timeout?: number | null;
    resolver?: (resolve: (result: ReturnType) => void, ...args: any[]) => void;
    callbackId?: string;
    defaultValue?: ReturnType;
  } = {},
) {
  const {
    timeout,
    resolver,
    callbackId = generateCallbackId(),
    defaultValue,
  } = options

  return new Promise<ReturnType>(resolve => {
    let timerId: number

    // 예외 상황일때 호출되지 않음. handleException 참고.
    const callback = (...data: any[]) => {
      resolver ? resolver(resolve, ...data) : resolve(data[0])
      if (timerId != null) {
        window.clearTimeout(timerId)
      }
    }

    const callbackJS = addNativeCallback(callback, callbackId)

    const handleException = () => {
      // callback 호출 과정에서 예외 상황이 발생했을때 nativeCallbacks 호출과 같은 동작 보장.
      resolve(defaultValue as ReturnType)
      removeNativeCallback(callbackId)
    }

    try {
      execute(callbackJS)
      if (timeout != null) {
        // 아래 상황을 대비해 수동 호출.
        // 1. iOS에 존재하지 않는 api 호출.
        // 2. 티맵 내부 서버 요청이 있어 timeout 시간안에 콜백 호출 못했을때.
        timerId = window.setTimeout(handleException, timeout)
      }
    } catch {
      // 아래 상황을 대비해 수동 호출.
      // 1. execute 내부 오류.
      // 2. 안드로이드에 존재하지 않는 api 호출.
      handleException()
    }
  })
}

/**
 * 낮은 버전 앱에서 존재하지 않는 펑션 호출 에러를 상쇄하기 위해 8.0.0 이상부터 추가된 펑션은 try/catch 감쌈.
 * @param fn
 * @param defaultValue
 */
function safetyCall<ReturnDataType = void>(
  fn: () => ReturnDataType extends infer U ? U : never,
  defaultValue?: ReturnDataType,
) {
  try {
    return fn()
  } catch {
    return defaultValue!
  }
}

function isLocalEnv() {
  return process.env.NODE_ENV !== 'production'
}

function applyUrlEncodedProperties(object: Dictionary) {
  return Object.keys(object).reduce((encoded, key) => {
    const value = object[key]
    encoded[key] = value != null ? encodeURIComponent(String(value)) : value
    return encoded
  }, {} as Dictionary)
}

export {
  PREFIX_CALLBACK_ID,
  nativeCallbacks,
  addNativeCallback,
  nativeEventListeners,
  addNativeEventListener,
  transformTipOffList,
  log,
  getTmapAppDownloadUrl,
  callCustomUrl,
  safetyAsyncCall,
  safetyCall,
  isLocalEnv,
  applyUrlEncodedProperties,
}
