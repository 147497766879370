/**
 * SK -> WGS84 좌표 변환.
 * 원본 소스 코드 https://github.com/tmobi-internal/tmap-navigation-engine/blob/develop/TmapEngineCommonData/src/main/java/com/skt/tmap/engine/navigation/coordination/TmapNaviPoint.java
 */
export function convertSkToWgs84({ x, y }: { x: number; y: number; }) {
  // SK To Bessel
  let besselX, besselY;
  if ((x / 10000000) < 1 && (y / 10000000) < 1) {
    // SK 7자리
    besselX = x / 36000
    besselY = y / 36000
  } else {
    // SK 8자리
    besselX = x / 360000
    besselY = y / 360000
  }

  // Bessel To WGS84
  return besselToWgs84({ x: besselX, y: besselY })
}

function besselToWgs84({ x, y }: { x: number; y: number; }) {
  const xyz = Geod2ECEF(y, x, 0, BESSEL_A, BESSEL_B);
  return ECEF2Geod(xyz.x + B2W_DELTAX, xyz.y + B2W_DELTAY, xyz.z + B2W_DELTAZ, WGS84_A, WGS84_B)
}

function Geod2ECEF(lat: number, lon: number, hei: number, a: number, b: number) {
  const lat_r = lat * Math.PI / 180.;
  const lon_r = lon * Math.PI / 180.;

  const f = (a - b) / a;
  const sqre = 2 * f - f * f;
  const N = a / Math.sqrt(1 - sqre * Math.sin(lat_r) * Math.sin(lat_r));

  return {
    x: (N + hei) * Math.cos(lat_r) * Math.cos(lon_r),
    y: (N + hei) * Math.cos(lat_r) * Math.sin(lon_r),
    z: (N * (1 - sqre) + hei) * Math.sin(lat_r),
  }
}

function ECEF2Geod(x: number, y: number, z: number, a: number, b: number) {
  const p = Math.sqrt(x * x + y * y);
  const theta = Math.atan((z * a) / (p * b));
  const sqrep = (a * a - b * b) / (b * b);

  const f = (a - b) / a;
  const sqre = 2 * f - f * f;

  const lat_r = Math.atan((z + sqrep * b * Math.sin(theta) * Math.sin(theta) * Math.sin(theta)) /
    (p - sqre * a * Math.cos(theta) * Math.cos(theta) * Math.cos(theta)));
  const lon_r = Math.atan2(y, x);

  return {
    latitude: lat_r * 180. / Math.PI,
    longitude: lon_r * 180. / Math.PI,
  }
}

const BESSEL_A = 6377397.155;
const BESSEL_RF = 299.1528128;
const BESSEL_B = BESSEL_A - BESSEL_A / BESSEL_RF;

const WGS84_A = 6378137;
const WGS84_RF = 298.257223563;
const WGS84_B = WGS84_A - WGS84_A / WGS84_RF;

const B2W_DELTAX = -147;
const B2W_DELTAY = 506;
const B2W_DELTAZ = 687;
